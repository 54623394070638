@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "poppins", sans-serif;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #919191;
}
.form-control {
  font-size: 13px;
  height: 45px;
  background-color: #f5f6fa;
  border: 1px solid #d5d5d5;
}
a {
  text-decoration: none;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.navbar-toggler:focus {
  box-shadow: unset !important;
}
.fw-sbold {
  font-weight: 600 !important;
}
.themeClr {
  color: #ff8682 !important;
}
.themeGradientClr {
  background: linear-gradient(86.11deg, #78fa8b 16.64%, #0000ff 183.32%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.dropdown .dropdown-toggle:after {
  display: none;
}
.box {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}
.notLastBorder > *:not(:last-child) {
  border-bottom: 1px solid #6e6e6e;
}
.cstmPagination .page-item.active .page-link {
  background: #1c58d3;
  border-color: #1c58d3;
}
input.form-check {
  accent-color: #6c6b6b;
}
.form-check-input:focus {
  box-shadow: unset;
  border-color: #ddd;
}
input#custom-switch:checked {
  background-color: #64b116;
}
.commonBtn {
  height: 48px;
  background: linear-gradient(318.39deg, #355e3b 15.7%, #0f0b41 84.3%);
  font-weight: 500;
  font-size: 14px;
  min-width: 130px;
  transition: 0.4s;
}
.commonBtn.borderedBtn {
  background-color: transparent !important;
  color: #76fc93 !important;
}
.commonBtn:hover,
.commonBtn.GreyBtn:hover {
  transform: translateY(-3px);
}
textarea {
  resize: none;
}
.box {
  background: #fbfbfb;
  box-shadow: 1px 1px 3.8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.form-control:focus {
  box-shadow: unset !important;
  border-color: #033e8a !important;
}
input.file {
  top: 0;
  left: 0;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}
.TableFilter .form-control {
  font-size: 12px;
  color: #4f4f4f;
  font-weight: 500;
}
.TableFilter .btn {
  height: 35px;
  font-size: 12px;
  font-weight: 600;
}
.commonBtn.GreyBtn {
  background: #4f4f4f;
  border-color: #4f4f4f;
}
.iconWithText .icn.position-absolute {
  top: 50%;
  transform: translateY(-50%);
}
.searchBox input {
  height: 40px;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  min-width: 200px;
  font-size: 12px;
  color: #c4c4c4;
  padding-right: 35px;
}
.tableFilter select.select.form-select {
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  min-width: 110px;
  background-color: #f7f8fa;
  border: 1px solid #eff0f4;
  border-radius: 8px;
  box-shadow: unset !important;
}
.searchBox input::placeholder {
  color: #c4c4c4;
}
.gap-10 {
  gap: 10px;
}
.otpWrp > div {
  justify-content: space-between;
  gap: 10px;
}
.otpWrp input {
  width: 70px !important;
  height: 55px;
  border-radius: 5px;
  border: 1px solid #a5c8f5;
  font-size: 21px;
  font-weight: 700;
}
.noLastBorder > *:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.react-tel-input .form-control {
  width: 100%;
  height: 45px;
}
.status {
  font-size: 12px;
  font-weight: 500;
  /* border: 1px solid #ddd; */
  height: 25px;
}
.greenStatus {
  border-color: #00b69b;
  color: #00b69b;
  background-color: #d5ffeb;
}
.redStatus {
  background: #ffd3d3;
  color: #fd5454;
  border-color: #fd5454;
}
/* pills tab */
.pillsTab.nav {
  flex-wrap: nowrap;
  padding: 3px;
  overflow: scroll;
  scrollbar-width: none;
}
.pillsTab.nav a.nav-link {
  font-weight: 500;
  color: #c2c2c2;
  min-width: 130px;
  white-space: nowrap;
}
.pillsTab.nav a.nav-link.active {
  color: #fff;
}
/* underline tab */
.underlineTab a.nav-link {
  color: #665c5c;
}
.underlineTab a.nav-link:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 3px;
  border-radius: 5px;
  background: #dc1919;
  width: 100%;
  transform: translateY(50%);
  opacity: 0;
}
.underlineTab a.nav-link.active {
  color: #000;
}
.underlineTab a.nav-link.active:after {
  opacity: 1;
}
header .dropdown:has(.dropdown-menu.show) .position-fixed {
  display: block !important;
}
.pac-container.pac-logo {
  z-index: 9999;
}
@media (min-width: 1200px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.Toastify__toast-container {
  z-index: 99999 !important;
}
